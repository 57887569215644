.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (change if needed) */
    height: 0;
    margin-bottom: 20px;
}

.youtube-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
